import "./App.css";
import "./Styles/fontfamily.css";

import NavbarComp from "./Component/NavbarComp";
import { FooterComp } from "./Component/FooterComp";

import MainRoutes from "./Pages/MainRoutes";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
    <BrowserRouter>
    
    <NavbarComp />
    <MainRoutes />
    <FooterComp />
    </BrowserRouter>
    </>
  );
}

export default App;
